<template>
    <div class="maincontent">
        <div class="container">
            <div class="row d-flex justify-content-center description mt-4">
                <div class="col-12 col-md-5 order-1 order-md-0 pt-4">
                    <h2 class="font-italic mb-5"><u>Datenschutzerklärung</u> </h2>
                    <h4 class="mb-4"><u>Datenschutz</u></h4>
                    <p class="mb-4">
                        Wir haben diese Datenschutzerklärung (Fassung 11.12.2020-321240248) verfasst, 
                        um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten 
                        verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
                        Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch 
                        bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
                    </p>
                    <h4 class="mb-4"><u>Automatische Datenspeicherung</u></h4>
                    <p>
                        Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.
                        Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) 
                        automatisch Daten wie:
                    </p>
                    <ul>
                        <li>die Adresse (URL) der aufgerufenen Webseite</li>
                        <li>Browser und Browserversion</li>
                        <li>das verwendete Betriebssystem</li>
                        <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
                        <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
                        <li>Datum und Uhrzeit</li>
                    </ul>
                    <p class="mb-4">
                        in Dateien (Webserver-Logfiles).
                        In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht 
                        weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
                    </p>
                    <h4 class="mb-4"><u>Speicherung persönlicher Daten</u></h4>
                    <p class="mb-4">
                        Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, 
                        Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von 
                        uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
                        Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die 
                        Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter,
                         können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
                        Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz 
                        Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
                        Die Rechtsgrundlage besteht nach Artikel 6  Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur 
                        Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, 
                        Sie finden unsere Kontaktdaten im Impressum.
                    </p>
                    <h4 class="mb-4"><u>Rechte laut Datenschutzgrundverordnung</u></h4>
                    <p>
                        Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
                    </p>
                    <ul>
                        <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
                        <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
                        <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                        <li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
                        <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                        <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
                        <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
                    </ul>
                    <p class="mb-4">
                        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen 
                        Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit 
                        (BfDI) wenden.
                    </p>
                    <h4 class="mb-4"><u>Auswertung des Besucherverhaltens</u></h4>
                    <p class="mb-4">
                        In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten.
                        Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf 
                        Ihre Person schließen. Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden 
                        Datenschutzerklärung.
                    </p>
                    <h4 class="mb-4"><u>TLS-Verschlüsselung mit https</u></h4>
                    <p class="mb-4">
                        Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). 
                        Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können 
                        wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol
                         links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
                    </p>
                    <h4 class="mb-4"><u>Google Maps Datenschutzerklärung</u></h4>
                    <p class="mb-4">
                        Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited 
                        (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen Standorte besser 
                        zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf 
                        den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch 
                        nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.
                        Was ist Google Maps?
                        Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue
                        Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden
                        neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines
                        Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. 
                        Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.
                        Warum verwenden wir Google Maps auf unserer Webseite?
                        All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die 
                        Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren
                        Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem
                        Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres 
                        Kundenservice.
                        Welche Daten werden von Google Maps gespeichert?
                        Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. 
                        Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten. 
                        Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den 
                        Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden 
                        haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt 
                        diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.
                        Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:
                        Name: NID
                        Wert: 188=h26c1Ktha7fCQTx8rXgLyATyITJ321240248-5
                        Verwendungszweck: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google
                        an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. 
                        Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.
                        Ablaufdatum: nach 6 Monaten
                        Anmerkung: Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies 
                        sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google 
                        Maps eingebunden war.
                        Wie lange und wo werden die Daten gespeichert?
                        Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden 
                        Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: 
                        <a href="https://www.google.com/about/datacenters/inside/locations/?hl=de" style="color:#212529">https://www.google.com/about/datacenters/inside/locations/?hl=de</a> 
                        Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen 
                        besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine 
                        Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.
                        Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. 
                        Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und 
                        Cookie-Informationen nach 9 bzw.18 Monaten löscht.
                        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
                        Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und 
                        Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten 
                        über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen 
                        Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option 
                        „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.
                        In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, 
                        funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
                        Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                        Safari: Verwalten von Cookies und Websitedaten mit Safari
                        Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
                        Internet Explorer: Löschen und Verwalten von Cookies
                        Microsoft Edge: Löschen und Verwalten von Cookies
                        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein 
                        Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.
                        Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten 
                        geregelt wird. Mehr Informationen dazu finden Sie auf 
                        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" style="color:#212529">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a> . 
                        Wenn Sie mehr über 
                        die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des 
                        Unternehmens unter
                        <a href="https://policies.google.com/privacy?hl=de" style="color:#212529">https://policies.google.com/privacy?hl=de</a>.
                    </p>
                    <h4 class="mb-4"><u>BootstrapCDN Datenschutzerklärung</u></h4>
                    <p class="mb-4">
                        Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website) auf allen Geräten schnell und sicher ausliefern zu können, 
                        nutzen wir das Content Delivery Network (CDN) BootstrapCDN des amerikanischen Software-Unternehmens StackPath, LLC 2012 McKinney Ave. 
                        Suite 1100, Dallas, TX 75201, USA.
                        Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander verbunden sind. Durch 
                        dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen schnell ausgeliefert werden.
                        BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden. Lädt nun Ihr Browser eine Datei
                        vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung zum Bootstrap-CDN-Server an das Unternehmen StockPath übermittelt.
                        StackPath erwähnt auch in der hauseigenen Datenschutzerklärung, dass das Unternehmen aggregierte und anonymisierte Daten von diversen Diensten 
                        (wie BootstrapCDN) für die Erweiterung der Sicherung und für andere StackPath-Dienste und Clients verwenden. All diese Daten können aber 
                        keine Person identifizieren.
                        Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen JavaScript-Blocker (siehe beispielsweise
                        <a href="https://noscript.net/" style="color:#212529">https://noscript.net/</a>) installieren 
                        oder in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. Bitte beachten Sie aber, dass dadurch die Website nicht mehr das 
                        gewohnte Service (wie etwa schnelle Ladegeschwindigkeit) bieten kann.
                        StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten 
                        geregelt wird. Mehr Informationen dazu finden Sie auf
                        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active" style="color:#212529">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active</a>.
                        Mehr Informationen zum Datenschutz bei StackPath bzw. BootstrapCDN finden Sie auf
                        <a href="https://www.bootstrapcdn.com/privacy-policy/" style="color:#212529">https://www.bootstrapcdn.com/privacy-policy/</a>.
                    </p>
                    <h4 class="mb-4"><u>jQuery CDN Datenschutzerklärung</u></h4>
                    <p class="mb-4">
                        Um Ihnen unsere Website bzw. all unsere einzelnen Unterseiten (Webseiten) auf unterschiedlichen Geräten schnell und problemlos 
                        auszuliefern, nutzen wir Dienste von jQuery CDN des Unternehmens jQuery Foundation. jQuery wird über das Content Delivery Network 
                        (CDN) des amerikanischen Software-Unternehmens StackPath (LCC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA) verteilt. Durch 
                        diesen Dienst werden personenbezogene Daten von Ihnen gespeichert, verwaltet und verarbeitet.
                        Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander verbunden sind. Durch 
                        dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen schnell ausgeliefert werden.
                        jQuery nutzt JavaScript-Bibliotheken, um unsere Website-Inhalte zügig ausliefern zu können. Dafür lädt ein CDN-Server die nötigen Dateien. 
                        Sobald eine Verbindung zum CDN-Server aufgebaut ist, wird Ihre IP-Adresse erfasst und gespeichert. Das geschieht nur, wenn diese Daten 
                        nicht schon durch einen vergangenen Websitebesuch in Ihrem Browser gespeichert sind.
                        In den Datenschutz-Richtlinien von StackPath wird ausdrücklich erwähnt, dass StackPath aggregierte und anonymisierte Daten von diversen 
                        Diensten (wie eben auch jQuery) für die Erweiterung der Sicherheit und für eigene Dienste benutzen. Diese Daten können Sie als Person 
                        allerdings nicht identifizieren.
                        Wenn Sie nicht wollen, dass es zu dieser Datenübertragung kommt, haben Sie immer auch die Möglichkeit Java-Scriptblocker wie beispielsweise 
                        ghostery.com oder noscript.net zu installieren. Sie können aber auch einfach in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. 
                        Wenn Sie sich für die Deaktivierung von JavaScript-Codes entscheiden, verändern sich auch die gewohnten Funktionen. So wird beispielsweise eine 
                        Website nicht mehr so schnell geladen.
                        StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten 
                        geregelt wird. Mehr Informationen dazu finden Sie auf
                        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active" style="color:#212529">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active</a>.
                        Mehr Informationen zum Datenschutz bei StackPath finden Sie unter
                        <a href="https://www.stackpath.com/legal/privacy-statement/" style="color:#212529">https://www.stackpath.com/legal/privacy-statement/</a>
                        und zu jQuery unter
                        <a href="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf" style="color:#212529">https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf</a>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
export default {
  name: 'Datenschutz',
  components: {
    // VueperSlides, VueperSlide
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
            slides:[
                {
                    image:"JVS90_Perspektiven.jpg",
                    title: 'Slide 1',
                    content: 'Slide 1 content.'
                }
            ]
        }
    },
    methods:{
        
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
.description{
    font-size: 1.2rem;
}
</style>